<template>
  <div class="row">
      <div v-if="!loaded">
          <svg-icon name="spinner"/>
          Loading data. Please wait
      </div>
    <div v-bind:class="[item.status.toLowerCase(), item.type.toLowerCase()]"
         class="sale_item col-lg-2 col-md-3 col-sm-4 col-6 d-flex flex-column justify-content-sm-center"
         v-for="item in sales"
         v-else-if = "sales && sales.length > 0"
         :key="item.id">
      <div class="sale_headlineIcon d-flex justify-content-md-left">
        <svg-icon v-if="item.type === 'EST'" name="tape"/>
        <svg-icon v-else-if="item.type === 'QOT' && item.status === 'PEND'" name="user-clock"/>
        <svg-icon v-else-if="item.type === 'QOT' && item.status === 'APPR'" name="handshake"/>
        <svg-icon v-else-if="item.type === 'EST'" name="clipboard"/>
      </div>
      <div class="sale_body">
            <span class="thumbnailsTable__id">
                <svg-icon name="file-alt" v-bind:class="[item.status.toLowerCase(), item.type.toLowerCase()]"/><span>{{item.id}}</span>
            </span>
        <span class="thumbnailsTable__user">
              <svg-icon name="user" v-bind:class="[item.status.toLowerCase(), item.type.toLowerCase()]"/><span>{{item.user}}</span>
            </span>
        <span class="thumbnailsTable__status">
              <svg-icon name="weight" v-bind:class="[item.status.toLowerCase(), item.type.toLowerCase()]"/><span class="text-capitalize">{{getStatus(item.status)}}</span>
            </span>
        <span class="thumbnailsTable__cost">
              <svg-icon name="money-check-alt" v-bind:class="[item.status.toLowerCase(), item.type.toLowerCase()]"/><span>{{item.cost}}</span>
            </span>
      </div>
      <button class="btn btn_default" @click="goTo(item)"><svg-icon v-if="item.type == 'QOT'" name="solid/tape" /><svg-icon v-else name="solid/clipboard-list" /> VIEW {{getState(item.type)}}</button>
    </div>
      <div v-else>
          No estimates or quotes found
      </div>
  </div>
</template>

<script>

    import router from "../../router";

export default {
  name: 'SalesItem',
    methods:{
      goTo(item){
          router.push({name : 'order-preview',params: { id: item.id }} );
      },
        getState(s){
            switch (s.toLowerCase())
            {
                case 'drft':
                    return 'Draft';
                case 'est':
                    return 'Estimate';
                case 'qot':
                    return 'Quote';
                case 'ord':
                    return 'Order';
            }
        },
        getStatus(s){
            switch (s.toLowerCase())
            {
                case 'drft':
                    return 'Draft';
                case 'pend':
                    return 'Pending Approval';
                case 'appr':
                    return 'Approved';
                case 'prod':
                    return 'In production';
            }
        }
    },
  computed: {
    sales() {
      return this.$store.getters['salesList/sales'];
    },
      loaded() {
          return this.$store.getters['salesList/loaded'];
      }
  },
    mounted(){
        this.$store.dispatch('salesList/loadSales');
    }
};
</script>

<style scoped>


</style>

<template>
  <div class="sales_section col-12">
    <div class="divider divider_bold">
      <div class=" container text_white titlePage_subtitle titlePage_bold">
        <h3>SALES & PROSPECTS</h3>
      </div>
    </div>
    <div class="container pl-md-3">
      <SalesItem/>
      <router-link :to="{name: 'orderList', query: { orderType: 'quote-and-estimate' }}" class="btn btn_default"><svg-icon name="solid/clock-rotate-left" /> VIEW RECENT ACTIVITY</router-link>
    </div>
  </div>
</template>

<script>
  import SalesItem from './SalesItem';


  export default {
    name: 'Sales',
    components: {SalesItem},
  };
</script>

<style scoped lang="scss">
  .titlePage_subtitle {
    padding-left: 20px;
  }

  .divider {
    &:before {
      display: none;
    }
  }
</style>
